@charset "utf-8";

/* CRONOLOGIA */
.crono_side {
    position: fixed;
    display: none;
    justify-content: center;
    height: 100vh;
    width: 3.75rem;
    right: 0;
    background-color: #fff;
    color: #7c121c;
    z-index: 100;
    transition: background-color 0.5s, color 0.5s;
}

.crono_side.show {
    display: flex;
}

.crono_arrow {
    display: none;
}

.crono_arrow {
    width: 1.125rem;
    height: 1.125rem;
    background-size: contain;
    z-index: 101;
}

body.show-crono-caller .crono_arrow2 {
    display: block;
}

.crono_arrow2 {
    position: fixed;
    bottom: 2.5rem;
    right: 1.375rem;
    background-image: url("/assets/media/graphics/arrow_left_red_dark.png");
}

.show-crono .crono_arrow2 {
    background-image: url("/assets/media/graphics/arrow_right_white.png");
}

.crono_side div {
    position: fixed;
    width: 100vh;
    text-align: center;
    transform: rotate(-90deg);
    transform-origin: center;
    bottom: 50%;
}

.crono_side div {
    font-family: "Oswald", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 300;
    cursor: pointer;
}

body.show-crono .crono_side {
    background-color: #7c121c;
    color: #fff;
}

.crono_side div span:first-child {
    font-weight: 700;
}

.crono_side_container {
    position: fixed;
    width: 40.5rem;
    height: 100vh;
    top: 0;
    right: -40.5rem;
    z-index: 99;
    background-color: #fff;
    overflow: auto;
    transition: right 0.5s;
}

.crono_side_container-inner {
    padding: 5.875rem 0 5.875rem 5.875rem;
    width: 18rem;
}

.crono_side_container.show {
    right: 3.75rem;
}

.crono-show-header {
    margin-bottom: 2rem;
}

.crono-show-header .title {
    font-family: "Oswald", sans-serif;
    display: block;
    color: #7c121c;
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: uppercase;
}

.crono-show-header .years {
    font-family: "Oswald", sans-serif;
    display: block;
    color: #7c121c;
    font-size: 3.25rem;
}

.crono_side_container-inner .crono-year-title {
    font-family: "Oswald", sans-serif;
    display: block;
    color: #030504;
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 3px solid #030504;
    padding: 1rem 0;
    margin: 1rem 0;
}

.crono_side_container-inner .crono-entry {
    margin: 2.25rem 0;
}

.crono_side_container-inner .crono-entry .crono-entry-dash {
    display: inline-block;
    width: 1.4rem;
    margin-right: 0.75rem;
    border-bottom: 3px solid #5a5a5a;
    position: absolute;
    margin-top: 0.8rem;
}

.crono_side_container-inner .crono-entry .crono-entry-date {
    display: inline-block;
    padding: 0;
    margin-left: 2.25rem;
    font-family: "Oswald", sans-serif;
    color: #5a5a5a;
    font-weight: 700;
}

.crono_side_container-inner .crono-entry .crono-entry-text {
    margin-left: 2.25rem;
    color: #5a5a5a;
    padding: 0.75rem 0;
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .crono_side {
        position: fixed;
        display: none;
        justify-content: center;
        width: 100vw;
        height: 3.75rem;
        bottom: 0;
        background-color: #fff;
        color: #7c121c;
        z-index: 100;
        transition: background-color 0.5s, color 0.5s;
    }

    .crono_side div {
        position: fixed;
        width: 100%;
        height: 100%;
        text-align: center;
        transform: unset;
        transform-origin: unset;
        bottom: unset;
    }

    .crono_side div span {
        line-height: 3.75rem;
    }

    .crono_side_container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 200vh;
        right: unset;
        z-index: 99;
        background-color: #fff;
        overflow: auto;
        transition: top 0.5s;
    }

    .crono_side_container.show {
        top: 0;
        right: unset;
    }

    .crono_arrow1 {
        position: fixed;

        bottom: 1.375rem;
        left: 6rem;
        background-image: url("/assets/media/graphics/up_arrow_red_dark.png");
    }

    body.show-crono-caller .crono_arrow1 {
        display: block;
    }

    .crono_arrow2 {
        position: fixed;
        width: 2.25rem;
        height: 2.25rem;
        bottom: 0.75rem;
        right: 6rem;
        background-image: url("/assets/media/graphics/up_arrow_circle.png");
    }

    .show-crono .crono_arrow1 {
        background-image: url("/assets/media/graphics/down_arrow_white.png");
    }

    .show-crono .crono_arrow2 {
        background-image: url("/assets/media/graphics/down_arrow_circle_white.png");
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    .crono_side {
        position: fixed;
        display: none;
        justify-content: center;
        width: 100vw;
        height: 3.75rem;
        bottom: 0;
        background-color: #fff;
        color: #7c121c;
        z-index: 100;
        transition: background-color 0.5s, color 0.5s;
    }

    .crono_side div {
        position: fixed;
        width: 100%;
        height: 100%;
        text-align: center;
        transform: unset;
        transform-origin: unset;
        bottom: unset;
    }

    body.show-crono-caller .crono_arrow1 {
        display: none;
    }

    body.show-crono-caller .crono_arrow2 {
        display: none;
    }

    .crono_side div span {
        line-height: 3.75rem;
    }

    .crono_side_container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 200vh;
        right: unset;
        z-index: 99;
        background-color: #fff;
        overflow: auto;
        transition: top 0.5s;
    }

    .crono_side_container.show {
        top: 0;
        right: unset;
    }

    .crono_side_container-inner {
        box-sizing: border-box;
        padding: 2rem 1rem 2rem 2rem;
        width: 100%;
    }
}
