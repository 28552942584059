body.europe_with_us_exhibit .pop-container-wrapper {
    background-color: #fff;
    padding: 2rem;
}

body.europe_with_us_exhibit .pop-container-wrapper .pop-container-wrapper_footer p {
    font-size: 0.875rem;
    font-style: italic;
    color: #737373;
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: normal;
}
