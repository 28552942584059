@charset "utf-8";

body.europe_with_us_exhibit #menu-call-container {
    background-color: unset;
}

body.europe_with_us_exhibit .center-block {
    width: 52.5rem;
}

body.europe_with_us_exhibit #verticalNav a .vNavText {
    background-color: #7C121C;
}

body.europe_with_us_exhibit #verticalNav a.selected .vNavCircle {
    background: url(/assets/media/graphics/nav_dot_selected_dark.png) no-repeat;
    background-size: contain;
}

body.europe_with_us_exhibit #verticalNav a .vNavCircle {
    background: url(/assets/media/graphics/nav_dot_dark.png) no-repeat;
    background-size: contain;
}

body.europe_with_us_exhibit #contacts .center-block {
    width: 60rem;
}

body.europe_with_us_exhibit #menuOut #menuEntries a, #menuOut #menuEntries a:hover {
    color: #7C121C;
}

body.europe_with_us_exhibit #menuOut #menuEntries ul li a {
    color: #737373;
}

body.europe_with_us_exhibit #pop33 {
    right: unset;
    left: 160px;
}

/* body.europe_with_us_exhibit .support_logos img {
    max-width: 95%;
} */

body.europe_with_us_exhibit.show-crono {
    overflow: hidden;
}

body.europe_with_us_exhibit section {
    min-height: 100vh;
}

body.europe_with_us_exhibit p {
    font-size: 1.125rem;
}

body.europe_with_us_exhibit p.small {
    font-size: 0.75rem;
    line-height: normal;
}

body.europe_with_us_exhibit .content {
    position: relative;
    margin: 2.5rem 0;
}

body.europe_with_us_exhibit #popBoxContainer .pop_info .info {
    margin-top: 3rem;
}

body.europe_with_us_exhibit .doc-white-box {
    padding: 2.5rem;
    background-color: #fff;
    color: #5a5a5a;
    box-shadow: 0 0 20px -2px #8a8888;
    margin: 4rem 0;
}

body.europe_with_us_exhibit .doc-white-box .split2Container {
    margin: 0;
}

body.europe_with_us_exhibit .content .pop-container {
    margin: 0.5rem 0;
}

body.europe_with_us_exhibit .content .pop-container.no-margin {
    margin: 0;
}

body.europe_with_us_exhibit .content .pop-container.smaller {
    width: 50%;
    margin: 0 auto;
}

body.europe_with_us_exhibit #axis1 {
    background-color: #eaeaea;
}

body.europe_with_us_exhibit #axis1 h2 {
    margin-top: 9.625rem;
}

body.europe_with_us_exhibit p.small_info {
    font-size: 0.75rem;
    color: #737373;
    line-height: normal;
}

body.europe_with_us_exhibit .sectionEnd {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    color: #afafaf;
    text-transform: uppercase;
    text-align: center;
    line-height: normal;
    margin: 6rem;
}

body.europe_with_us_exhibit .sectionEnd span {
    display: block;
}

body.europe_with_us_exhibit .sectionEnd .sectionEnd_big {
    font-size: 5.75rem;
    line-height: 5rem;
}

body.europe_with_us_exhibit .sectionEnd .sectionEnd_small {
    font-size: 1.875rem;
}

body.europe_with_us_exhibit .sectionEnd a {
    display: block;
    margin: 0.5rem 0;
}

body.europe_with_us_exhibit section h2 {
    margin-bottom: 3.8rem;
}

#axis1 h2 {
    color: #1d1d1b;
    font-family: "Oswald", sans-serif;
    line-height: 4.5rem;
    margin-top: 6rem;
}

#axis1 h2 span {
    font-size: 2rem;
}

/* AXIS 2 */
body.europe_with_us_exhibit #axis2 {
    background-color: #eaeaea;
    background-image: url("/assets/media/images/axis2_background@2x.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

body.europe_with_us_exhibit #axis2 h2 span:first-child {
    display: block;
    color: #7c121c;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    line-height: 5.2rem;
    text-align: center;
    font-weight: 700;
    letter-spacing: -3.68px;
}

body.europe_with_us_exhibit #axis2 h2 span:nth-child(2) {
    color: #1d1d1b;
    font-family: "Oswald", sans-serif;
    font-size: 1.875rem;
    display: block;
    text-align: center;
    font-weight: 700;
    letter-spacing: -1.2px;
}

body.europe_with_us_exhibit #axis2 .attached_quote_1 {
    background-color: #7c121c;
    color: #fff;
    padding: 5rem 6rem;
    margin: 0;
    text-align: right;
}

body.europe_with_us_exhibit #axis2 .attached_quote_1 p.quote {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: -0.76px;
    font-size: 2.1rem;
    line-height: 2.8125rem;
}

body.europe_with_us_exhibit #axis2 .layered_quote {
    position: absolute;
    color: #fff;
    margin: 10rem 5rem;
    width: 22rem;
    top: 0;
}

body.europe_with_us_exhibit #axis2 .layered_quote .quote_sign {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 8rem;
}

body.europe_with_us_exhibit #axis2 .layered_quote p.quote {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
}

body.europe_with_us_exhibit #axis2 .quote-red {
    background-color: #7c121c;
    color: #fff;
    padding: 5.6rem 10rem;
}

body.europe_with_us_exhibit #axis2 .quote-red p.quote {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.2rem;
}

body.europe_with_us_exhibit #axis2 .quote-red p.footnote {
    font-size: 1rem;
}

/* AXIS 3 */
body.europe_with_us_exhibit #axis3 {
    background-color: #f7f7f7;
    background-image: url("/assets/media/images/axis3_background@2x.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: fixed;
}

body.europe_with_us_exhibit #axis3 h2 span:first-child {
    display: block;
    color: #7c121c;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    line-height: 5.2rem;
    text-align: center;
    font-weight: 400;
    letter-spacing: -3.68px;
}

body.europe_with_us_exhibit #axis3 h2 span:nth-child(2) {
    color: #7c121c;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    display: block;
    text-align: center;
    font-weight: 700;
    letter-spacing: -3.68px;
}

body.europe_with_us_exhibit .white_block {
    background-color: #fff;
    color: #7c121c;
    font-family: "Chivo", sans-serif;
    padding: 5.6rem 6rem;
    box-shadow: 0 0 20px -2px #8a8888;
}

body.europe_with_us_exhibit .white_block.white_block_large .quote p {
    font-size: 1.875rem;
    line-height: 2.25rem;
    letter-spacing: -1.2px;
}

body.europe_with_us_exhibit .white_block_small {
    background-color: #fff;
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
}

body.europe_with_us_exhibit .transparent_block_small {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
}

body.europe_with_us_exhibit .white_block_small.withShadow {
    box-shadow: 10px 10px 12px #1a181899;
}

body.europe_with_us_exhibit .white_block .quote {
    font-size: 1.875rem;
}

body.europe_with_us_exhibit .white_block .footnote {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 3rem;
    line-height: 1.1rem;
}

body.europe_with_us_exhibit .black_box_video {
    background-color: #030504;
    padding: 1.2rem;
    color: #fff;
}

body.europe_with_us_exhibit .black_box_video p {
    font-size: 1rem;
}

/* AXIS 4 */
body.europe_with_us_exhibit #axis4 {
    background-color: #f7f7f7;
}

body.europe_with_us_exhibit #axis4 .round_maps {
    position: relative;
    text-align: center;
    /* max-height: 1080px;
    background-image: url("/assets/media/graphics/ronda1@2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
}

body.europe_with_us_exhibit #axis4 .round_maps .buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40px;
    bottom: 1rem;
    text-align: center;
}

body.europe_with_us_exhibit #axis4 .round_maps .buttons .button {
    font-family: "Chivo", sans-serif;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    height: 100%;
    line-height: 2.5rem;
    margin-right: 1.5rem;
    padding: 0 2rem;
    color: #fff;
    background-color: #afafaf;
    border-radius: 1.25rem;
    cursor: pointer;
}

body.europe_with_us_exhibit #axis4 .round_maps .round_map {
    max-width: 100%;
    margin: 0 auto;
    display: none;
}

body.europe_with_us_exhibit #axis4 .round_maps.round1 #round1_map {
    display: block;
}

body.europe_with_us_exhibit #axis4 .round_maps.round1 #round_button1,
body.europe_with_us_exhibit #axis4 .round_maps.round3 #round_button1 {
    background-color: #1d1d1b;
}

body.europe_with_us_exhibit #axis4 .round_maps.round2 #round_button2,
body.europe_with_us_exhibit #axis4 .round_maps.round3 #round_button2 {
    background-color: #7c121c;
}

body.europe_with_us_exhibit #axis4 .round_maps.round2 #round2_map {
    display: block;
}

body.europe_with_us_exhibit #axis4 .round_maps.round3 #round3_map {
    display: block;
}

body.europe_with_us_exhibit #axis4 h2 span:first-child {
    display: block;
    color: #7c121c;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    line-height: 5.2rem;
    text-align: center;
    font-weight: 400;
    letter-spacing: -3.68px;
}

body.europe_with_us_exhibit #axis4 h2 span:nth-child(2) {
    color: #7c121c;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    display: block;
    text-align: center;
    font-weight: 700;
    letter-spacing: -3.68px;
}

body.europe_with_us_exhibit #axis4 .round_box {
    padding: 4rem;
    color: #fff;
}

body.europe_with_us_exhibit #axis4 .round_box.black {
    background-color: #1d1d1b;
}

body.europe_with_us_exhibit #axis4 .round_box.gray {
    background-color: gray;
}

body.europe_with_us_exhibit #axis4 .round_box.gray p.small_info {
    color: #030504;
}

body.europe_with_us_exhibit #axis4 .round_box.red {
    background-color: #7c121c;
}

body.europe_with_us_exhibit #axis4 .round_box .round_title {
    font-family: "Oswald", sans-serif;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    text-transform: uppercase;
}

body.europe_with_us_exhibit #axis4 .round_box .round_highlight p {
    font-family: "Chivo", sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: normal;
    padding: 3rem;
}

body.europe_with_us_exhibit #axis4 .round_box .round_footnote p {
    font-size: 0.9rem;
    padding: 0 3rem;
    line-height: normal;
}

body.europe_with_us_exhibit #axis4 .round_date {
    font-family: "Oswald", sans-serif;
    color: #1d1d1b;
    text-transform: uppercase;
    text-align: center;
    margin: 2rem 0;
}

body.europe_with_us_exhibit #axis4 .round_date .round_date_number {
    width: 2.625rem;
    height: 2.625rem;
    line-height: 2.625rem;
    text-align: center;
    border-radius: 1.3125rem;
    background-color: #040505;
    color: #fff;
    font-size: 1.5rem;
    margin: 0 auto;
}

body.europe_with_us_exhibit #axis4 .round_date.red .round_date_number {
    background-color: #7c121c;
}

body.europe_with_us_exhibit #axis4 .round_date.red {
    color: #7c121c;
}

body.europe_with_us_exhibit #axis4 .round_date_place_date {
    margin: 0.5rem 0;
    font-size: 2rem;
}

body.europe_with_us_exhibit #axis4 .round_date_place_date span:nth-child(1) {
    font-weight: 700;
}

body.europe_with_us_exhibit #axis4 .round_white_box {
    background-color: #fff;
    padding: 3.125rem 5.4rem;
    border: 1px solid #707070;
    box-shadow: 10px 10px 6px #00000029;
}

body.europe_with_us_exhibit #axis4 .round_white_box p {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
}

body.europe_with_us_exhibit #axis4 .round_white_box .round_white_box_footnote p {
    font-weight: 400;
    font-size: 1rem;
}

/* AXIS 5 */
body.europe_with_us_exhibit #axis5 {
    background-color: #eaeaea;
}

body.europe_with_us_exhibit #axis5 h2 span:first-child {
    display: block;
    color: #7c121c;
    font-family: "Oswald", sans-serif;
    font-size: 7.5rem;
    line-height: 5.2rem;
    text-align: center;
    font-weight: 700;
    letter-spacing: -3.68px;
    line-height: 8rem;
}

body.europe_with_us_exhibit #axis5 .round_white_box {
    background-color: #fff;
    color: #7c121c;
    padding: 3.125rem 5.4rem;
    border: 1px solid #707070;
    box-shadow: 10px 10px 6px #00000029;
}

body.europe_with_us_exhibit #axis5 .round_white_box p {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: normal;
}

body.europe_with_us_exhibit #axis5 .round_white_box .round_white_box_footnote p {
    font-weight: 400;
    font-size: 1rem;
    line-height: normal;
}

body.europe_with_us_exhibit .split2Container {
    margin: 0;
}

.split2Container.split2Container_large > *:first-child {
    margin-right: 0;
}

.split2Container.split2Container_large *:nth-child(2) {
    margin-left: 0;
}

.split2Container.split2Container_large.shadow {
    line-height: 0;
}

.split2Container.split2Container_large.shadow > *:first-child {
    z-index: 20;
    box-shadow: 10px 0px 10px #ccc;
}

.split2Container.split2Container_large.shadow .pop-container {
    margin: 0;
    padding: 0;
}

body.europe_with_us_exhibit #axis5 .sectionEnd .sectionEnd_big {
    font-size: 4rem;
}

/* AXIS 6 */
body.europe_with_us_exhibit #axis6 {
    padding-top: 0;
    background-image: url("/assets/media/images/axis6_background@2x.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

body.europe_with_us_exhibit #axis6 h2 {
    background-color: #7c121c;
    margin: 0 0 2rem 0;
    padding-top: 9rem;
}

body.europe_with_us_exhibit #axis6 h2 span:first-child {
    display: block;
    color: #fff;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    line-height: 5.75rem;
    text-align: center;
    font-weight: 700;
    letter-spacing: -3.68px;
}

body.europe_with_us_exhibit #axis6 h2 span:nth-child(2) {
    display: block;
    font-family: "Oswald", sans-serif;
    font-size: 5.75rem;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    font-weight: 400;
}

body.europe_with_us_exhibit #axis6 .round_white_box {
    background-color: #fff;
    color: #7c121c;
    padding: 3.125rem 5.4rem;
    border: 1px solid #707070;
    box-shadow: 10px 10px 6px #00000029;
}

body.europe_with_us_exhibit #axis6 .round_white_box p {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: normal;
}

body.europe_with_us_exhibit #axis6 .round_white_box .round_white_box_footnote p {
    font-weight: 400;
    font-size: 1rem;
    line-height: normal;
}

body.europe_with_us_exhibit #axis6 .round_box.red,
body.europe_with_us_exhibit #axis7 .round_box.red {
    background-color: #7c121c;
    padding: 4rem;
    color: #fff;
}

body.europe_with_us_exhibit #axis6 .round_box .round_title,
body.europe_with_us_exhibit #axis7 .round_box .round_title {
    font-family: "Oswald", sans-serif;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    text-transform: uppercase;
}

body.europe_with_us_exhibit #axis6 .round_box .round_highlight p {
    font-family: "Chivo", sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: normal;
    padding: 3rem;
}

body.europe_with_us_exhibit #axis7 .round_box .round_highlight p {
    font-family: "Chivo", sans-serif;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: normal;
    padding: 3rem;
}

body.europe_with_us_exhibit #axis6 .round_box .round_footnote p,
body.europe_with_us_exhibit #axis7 .round_box .round_footnote p {
    font-size: 0.9rem;
    padding: 0 3rem;
    line-height: normal;
}

/* AXIS 7 */
body.europe_with_us_exhibit #axis7 {
    background-color: #eaeaea;
    padding-top: 0;
    background-image: url("/assets/media/images/axis7_background@2x.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: fixed;
}

body.europe_with_us_exhibit #axis7 h2 {
    font-family: "Oswald", sans-serif;
    text-align: center;
    letter-spacing: -4.8px;
    color: #7c121c;
    font-size: 7.5rem;
    margin: 0 0 2rem 0;
    padding-top: 9rem;
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body.europe_with_us_exhibit .center-block {
        width: auto;
    }

    body.europe_with_us_exhibit #menu-call-container {
        background-color: #7c121c;
    }

    body.europe_with_us_exhibit #menu-call-container a {
        color: #ffffff;
    }

    body.europe_with_us_exhibit #langSelection a {
        color: #ffffff;
    }

    #axis1 h2 {
        font-size: 3.75rem;
        line-height: 3rem;
    }

    body.europe_with_us_exhibit #axis2 .attached_quote_1 p.quote {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    body.europe_with_us_exhibit #axis2 .layered_quote {
        position: absolute;
        color: #fff;
        margin: 4rem 2rem;
        width: 15rem;
    }

    body.europe_with_us_exhibit #axis2 .layered_quote p.quote {
        font-family: "Chivo", sans-serif;
        font-weight: 700;
        font-size: 1rem;
    }

    body.europe_with_us_exhibit #axis2 .quote-red {
        padding: 5rem 3rem;
    }

    body.europe_with_us_exhibit #axis2 .quote-red p.quote {
        font-family: "Chivo", sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: normal;
    }

    body.europe_with_us_exhibit #axis4 .round_box .round_title {
        font-size: 2.1rem;
    }

    body.europe_with_us_exhibit #axis4 .round_box .center-block {
        padding: 0;
    }

    body.europe_with_us_exhibit #axis4 .round_box .round_highlight p {
        font-size: 1.375rem;
        padding: 3rem 0;
    }

    body.europe_with_us_exhibit #axis4 .round_box .round_footnote p {
        padding: 0;
    }

    body.europe_with_us_exhibit #axis6 h2 {
        padding-bottom: 2.5rem;
    }

    body.europe_with_us_exhibit #axis6 h2 span:first-child,
    body.europe_with_us_exhibit #axis6 h2 span:nth-child(2) {
        font-size: 3.75rem;
    }

    body.europe_with_us_exhibit #axis7 {
        background-size: 150%;
    }

    body.europe_with_us_exhibit #axis7 h2 {
        font-size: 3.75rem;
    }

    body.europe_with_us_exhibit footer .center-block {
        width: 80%;
        padding: 0;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    body.europe_with_us_exhibit #menu-call-container {
        background-color: #7c121c;
    }

    body.europe_with_us_exhibit #menu-call-container a {
        color: #ffffff;
    }

    body.europe_with_us_exhibit #langSelection a {
        color: #ffffff;
    }

    body.europe_with_us_exhibit .support_logos {
        text-align: left;
    }

    body.europe_with_us_exhibit .center-block {
        width: auto;
        padding: 0;
    }

    body.europe_with_us_exhibit .center-block .content {
        padding: 0 2rem;
    }

    body.europe_with_us_exhibit .center-block .content.mobileWide {
        padding: 0 0;
    }

    body.europe_with_us_exhibit p {
        font-size: 0.875rem;
    }

    body.europe_with_us_exhibit #axis1 h2 {
        padding: 0 2rem;
        margin: 4rem 0 2rem 0;
        font-size: 2.625rem;
        line-height: 2.6rem;
    }

    body.europe_with_us_exhibit #axis1 h2 span {
        font-size: 1.2rem;
    }

    body.europe_with_us_exhibit #axis2 h2 span:first-child {
        padding: 0 2rem;
        font-size: 2.625rem;
        line-height: 2.6rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis2 h2 span {
        font-size: 1.2rem;
    }

    body.europe_with_us_exhibit #axis2 h2 span:nth-child(2) {
        font-size: 1.2rem;
    }

    body.europe_with_us_exhibit #axis3 h2 span:first-child {
        padding: 0 2rem;
        font-size: 2.625rem;
        line-height: 2.6rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis3 h2 span {
        font-size: 1.4rem;
    }

    body.europe_with_us_exhibit #axis3 h2 span:nth-child(2) {
        font-size: 2.625rem;
        line-height: 2.8rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis4 h2 span:first-child {
        font-size: 2.625rem;
        line-height: 2.8rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis4 h2 span:nth-child(2) {
        font-size: 2.625rem;
        line-height: 2.8rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis6 h2 {
        margin: 0 2rem;
        padding: 4rem 0.8rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis6 h2 span:first-child {
        font-size: 2.625rem;
    }

    body.europe_with_us_exhibit #axis6 h2 span:nth-child(2) {
        font-size: 1.75rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis7 h2 {
        font-size: 2.625rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit #axis2 .attached_quote_1 {
        padding: 2rem;
    }

    body.europe_with_us_exhibit #axis2 .attached_quote_1 p.quote {
        font-size: 0.75rem;
        line-height: normal;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit p.small_info {
        margin-top: 0.5rem;
        margin-left: 0;
        margin-right: 0;
    }

    body.europe_with_us_exhibit .sectionEnd {
        margin: 3rem 0;
    }

    body.europe_with_us_exhibit .sectionEnd .sectionEnd_big {
        font-size: 3.75rem;
        line-height: normal;
    }

    body.europe_with_us_exhibit #axis5 .sectionEnd .sectionEnd_big,
    body.europe_with_us_exhibit #axis6 .sectionEnd .sectionEnd_big {
        font-size: 2.625rem;
    }

    body.europe_with_us_exhibit .sectionEnd .sectionEnd_small {
        font-size: 1.1rem;
    }

    body.europe_with_us_exhibit #axis5 .sectionEnd .sectionEnd_small {
        font-size: 2.4rem;
        font-weight: 400;
    }

    body.europe_with_us_exhibit #axis2 .layered_quote {
        position: relative;
        color: #7c121c;
        margin: 4rem 2rem;
        width: 15rem;
    }

    body.europe_with_us_exhibit #axis2 .layered_quote p.quote {
        font-family: "Chivo", sans-serif;
        font-weight: 700;
        font-size: 1rem;
    }

    body.europe_with_us_exhibit #axis2 .quote-red {
        padding: 5rem 3rem;
    }

    body.europe_with_us_exhibit #axis2 .quote-red p.quote {
        font-family: "Chivo", sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: normal;
    }

    body.europe_with_us_exhibit .white_block {
        padding: 3.5rem 2rem;
    }

    body.europe_with_us_exhibit .white_block_small {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
    }

    body.europe_with_us_exhibit #axis4 .round_maps .buttons .button {
        font-size: 0.7rem;
        padding: 0 0.5rem;
        line-height: 1.8rem;
    }

    body.europe_with_us_exhibit #axis4 .round_maps .buttons {
        position: relative;
        bottom: unset;
        margin: 0.5rem 0;
        height: 1.8rem;
    }

    body.europe_with_us_exhibit #axis4 .round_box .round_title {
        font-size: 2.625rem;
    }

    body.europe_with_us_exhibit #axis4 .round_box,
    body.europe_with_us_exhibit #axis6 .round_box.red,
    body.europe_with_us_exhibit #axis7 .round_box.red {
        padding: 4rem 2rem;
    }

    body.europe_with_us_exhibit #axis4 .round_box .round_highlight p,
    body.europe_with_us_exhibit #axis6 .round_box .round_highlight p,
    body.europe_with_us_exhibit #axis7 .round_box .round_highlight p {
        font-size: 1rem;
        padding: 2rem 0;
    }

    body.europe_with_us_exhibit #axis4 .round_date_place_date {
        font-size: 1.6rem;
    }

    body.europe_with_us_exhibit #axis4 .round_box .round_footnote p,
    body.europe_with_us_exhibit #axis5 .round_box .round_footnote p,
    body.europe_with_us_exhibit #axis6 .round_box .round_footnote p,
    body.europe_with_us_exhibit #axis7 .round_box .round_footnote p {
        padding: 0;
    }

    body.europe_with_us_exhibit #axis4 .round_white_box,
    body.europe_with_us_exhibit #axis5 .round_white_box {
        padding: 3.125rem 2rem;
    }

    body.europe_with_us_exhibit #axis4 .round_white_box p,
    body.europe_with_us_exhibit #axis5 .round_white_box p {
        font-size: 1.1rem;
        line-height: normal;
    }

    body.europe_with_us_exhibit #axis4 .round_white_box .round_white_box_footnote p,
    body.europe_with_us_exhibit #axis5 .round_white_box .round_white_box_footnote p {
        font-size: 0.8rem;
    }

    body.europe_with_us_exhibit #axis5 h2 span:first-child {
        font-size: 2.625rem;
        letter-spacing: normal;
    }

    body.europe_with_us_exhibit .content.mobileWide p.small_info {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    body.europe_with_us_exhibit #axis7 {
        background-size: 160%;
        background-position: center center;
    }
}
