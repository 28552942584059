@charset "utf-8";

.toPopBox {
    box-shadow: 0px 0px 20px -2px rgba(138, 136, 136, 1);
    cursor: pointer;
}

.toPopBox.noShadow {
    box-shadow: unset;
}

img.toPopBox {
    box-shadow: 0px 0px 20px -2px rgba(138, 136, 136, 1);
}

#popBoxTintLayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 140, 255, 0.8);
    z-index: 100;
    cursor: pointer;
}

body.col_europe #popBoxTintLayer,
body.europe_with_us_exhibit #popBoxTintLayer {
    background-color: rgba(124, 0, 26, 0.8);
}

#popBoxContainerContainer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    pointer-events: none;
}

#popBoxContainer {
    display: flex;
    justify-content: center;
    top: 10%;
    left: 10%;
    width: 80%;
    max-height: 80%;
    z-index: 102;
    pointer-events: none;
}

#popBoxContainerInner {
    position: relative;
    display: flex;
    max-width: 100%;
    justify-content: center;
    box-shadow: 0 0 20px -2px #000000;
}

.pop_main {
    display: flex;
    width: 60rem;
    height: 100%;
    box-sizing: border-box;
    border: 1rem solid #ffffff;
    border-bottom: 0;
    background-color: #fff;
    pointer-events: all;
    justify-content: center;
    align-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.pop_main img {
    visibility: hidden;
    max-width: 100%;
    max-height: 100%;
}

.pop_info {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 30rem;
    background-color: #1a347d;
    color: #fff;
    padding: 1.2rem;
    pointer-events: all;
    overflow: auto;
}

body.col_europe .pop_info,
body.europe_with_us_exhibit .pop_info {
    background-color: rgba(124, 0, 26, 1);
}

.pop_info a {
    color: #fff;
}

.pop_info .header {
    font-family: "Oswald", sans-serif;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #eaeaea;
}

.pop_info .popClose {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
    cursor: pointer;
}

.pop_info .popClose img {
    pointer-events: none;
}

.pop_info .title {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    margin: 3rem 0;
}

.pop_info .info {
    font-family: "Chivo", sans-serif;
    font-size: 1.1rem;
}

.pop_info .source a {
    display: block;
    font-size: 0.8rem;
    font-family: "Chivo", sans-serif;
    font-style: italic;
    color: #b4b4b4;
    margin: 3rem 0;
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #popBoxContainerInner {
        flex-direction: column;
    }

    #popBoxContainerInner .pop_info {
        max-width: unset;
        width: 100%;
        overflow: auto;
    }

    .pop_info .title,
    .pop_info .info {
        font-size: 1rem;
    }

    .pop_info .source a {
        font-size: 0.8rem;
    }

    .pop_main {
        width: 100%;
        max-height: 66%;
    }

    .pop_info {
        width: 100%;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #popBoxContainerInner {
        flex-direction: column;
    }

    #popBoxContainerInner .pop_info {
        max-width: unset;
        width: 100%;
    }

    .pop_main {
        width: 100%;
        max-height: 66%;
    }

    .pop_info {
        width: 100%;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .pop_main {
        width: 40rem;
    }

    .pop_info {
        width: 20rem;
    }
}
