@charset "utf-8";

@import "normalize.css";
@import "fonts.css";

@import "components/main-nav.css";
@import "components/vertical-nav.css";
@import "components/pop-box.css";
@import "components/hero.css";
@import "components/hero_col_europe.css";
@import "components/hero-ms.css";
@import "components/exhibitions.css";
@import "components/contents.css";
@import "components/bibliography_collection.css";
@import "components/crono.css";
@import "components/msoares.css";
@import "components/fmsmb.css";
@import "components/team.css";

@import "components/europe_with_us/intro.css";
@import "components/europe_with_us/exhibit.css";
@import "components/europe_with_us/crono.css";
@import "components/europe_with_us/quote_box.css";
@import "components/europe_with_us/pop_container_wrapper.css";

:root {
    /* stylelint-disable-next-line unit-whitelist */
    --font-size: 16px;
}

html,
body {
    box-sizing: border-box;
    font-family: "Chivo", sans-serif;
    font-size: var(--font-size);
    scroll-behavior: smooth;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    color: #1d1d1b;
}

html.scrollSnap {
    /*scroll-snap-type: y mandatory; */
}

html.scrollSnap > body > section#hero,
html.scrollSnap > body > section#biography {
    scroll-snap-align: start;
}

p:first-child {
    margin-top: 0;
}

body > section {
    z-index: 1;
    width: 100%;
    padding: 4rem 0;
}

.center-block {
    margin: 0 auto;
    width: 68rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
}

.splitColumns {
    column-count: 2;
    column-gap: 1rem;
    margin: 3rem 0;
}

.split2Container,
.split2Container4-3 {
    margin: 3rem 0;
    display: flex;
    align-items: flex-start;
}

.split2Container.bottom {
    align-items: flex-end;
}

.split2Container > * {
    width: 50%;
}

.split2Container4-3 > *:first-child {
    width: 60%;
}

.split2Container4-3 > *:nth-child(2) {
    width: 40%;
}

.split2Container > *:first-child,
.split2Container4-3 > *:first-child {
    margin-right: 0.5rem;
}

.split2Container > *:nth-child(2) {
    margin-left: 0.5rem;
}

section h2 {
    font-family: "Chivo";
    font-weight: 900;
    text-transform: uppercase;
    color: #b30437;
    font-size: 5rem;
    line-height: initial;
    margin-bottom: 0.8rem;
}

section .quoteHighlight {
    font-family: "Chivo";
    font-weight: 400;
    font-size: 3.75rem;
    line-height: 4rem;
    margin-top: 0;
}

section .quoteHighlight2 {
    font-family: "Chivo";
    font-weight: 900;
    text-transform: uppercase;
    color: #b30437;
    font-size: 3.375rem;
    line-height: 3.375rem;
}

section .quoteHighlight2 a {
    color: #b30437;
}

section .quoteHighlight3 {
    font-family: "Chivo";
    font-weight: 900;
    text-transform: uppercase;
    color: #b30437;
    font-size: 2rem;
    line-height: 2.2rem;
}

section .quoteHighlight3 a {
    color: #b30437;
}

.pop-container {
    position: relative;
}

.pop-container .pop-plus {
    position: absolute;
    bottom: 1.4rem;
    right: 1.4rem;
    pointer-events: none;
}

.pop-container img {
    max-width: 100%;
}

#biography {
    background-image: url("../media/images/varanda_background.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

#humanist {
    background-image: url("../media/images/city_brackground.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

#liberty {
    background-image: url("../media/images/varanda_background.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

#democracy {
    background-image: url("../media/images/city_brackground.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

#europe {
    background-image: url("../media/images/varanda_background.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

#world {
    background-image: url("../media/images/city_brackground.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

.section_end {
    margin: 0 auto;
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.section_end img {
    margin: 0 auto;
    display: block;
}

.section_end a {
    font-weight: 900;
    font-size: 5rem;
    line-height: 5rem;
    color: #afafaf;
    text-transform: uppercase;
    text-decoration: none;
}

.section_end span {
    display: block;
    margin-top: 1.5rem;
}

.section_end.document_end a {
    color: #b30437;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 1.5rem;
    text-decoration: underline;
}

.center-highlight {
    border: 5px solid #b30437;
    padding: 4rem;
}

.center-highlight .center-highlight-main {
    font-family: "Chivo", sans-serif;
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: 700;
    color: #737373;
}

.center-highlight .center-highlight-footer {
    display: flex;
    font-family: "Chivo", sans-serif;
    font-style: italic;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #737373;
    line-height: 1rem;
}

.center-highlight .center-highlight-footer .plus {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.center-highlight .center-highlight-footer a,
.center-highlight .center-highlight-footer a:hover {
    color: #737373;
}

footer {
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem 0;
    background-color: #737373;
    color: #ffffff;
}

footer h2 {
    font-size: 5rem;
    text-transform: uppercase;
    font-family: "oswald", sans-serif;
}

footer .logos {
    margin: 3rem 0;
    display: flex;
    flex-direction: row;
}

footer .logos .logo {
    display: flex;
    margin-right: 2.75rem;
    align-items: center;
}

footer .logos .logo img {
    max-width: 100%;
}

body.col_europe footer {
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem 0;
    background-color: #b30437;
    color: #ffffff;
}

body.col_europe footer h2 {
    font-size: 5rem;
    text-transform: uppercase;
    font-family: "oswald", sans-serif;
}

body.col_europe footer a,
body.col_europe footer a:hover {
    color: #ffffff;
    text-decoration: none;
}

body.col_europe footer .center-block {
    width: 92.375rem;
}

body.col_europe footer .center-block .split2Container > *:first-child {
    margin-right: 2rem;
}

body.col_europe footer .center-block .split2Container > *:nth-child(2) {
    margin-left: 2rem;
}

body.col_europe footer #headerphone {
    display: none;
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    section h2 {
        font-family: "Chivo";
        font-weight: 900;
        text-transform: uppercase;
        color: #b30437;
        font-size: 2.5rem;
    }

    section p.quoteHighlight {
        display: block;
        margin: 1rem 0;
        font-size: 2rem;
        line-height: 2.375rem;
    }

    section .quoteHighlight2 {
        display: block;
        margin: 1rem 0;
        font-size: 1.75rem;
        line-height: 1.875rem;
    }

    section .quoteHighlight3 {
        display: block;
        margin: 1rem 0;
        font-size: 1.75rem;
        line-height: 1.875rem;
    }

    .center-block {
        box-sizing: border-box;
        width: 100%;
        padding: 0 6rem;
    }

    .center-block p {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .split2Container {
        margin: 2.5rem 0;
        display: flex;
        flex-direction: row;
    }

    .split2Container > * {
        width: 50%;
    }

    .splitColumns {
        column-count: 2;
        margin: 0;
    }

    footer h2 {
        font-size: 2.5rem;
    }

    .section_end a {
        font-weight: 900;
        font-size: 2.625rem;
        line-height: 2.725rem;
    }

    body.col_europe footer h2 {
        font-size: 2.5rem;
        text-transform: uppercase;
        font-family: "oswald", sans-serif;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    .center-block {
        box-sizing: border-box;
        width: 100%;
        font-size: 0.875rem;
    }

    .center-block p {
        line-height: 1rem;
    }

    .split2Container p {
        margin: 1rem 2.25rem;
    }

    h2 {
        margin: 0 2.25rem;
        font-size: 2.25rem;
        line-height: 2.5rem;
    }

    section h2 {
        font-family: "Chivo";
        font-weight: 900;
        text-transform: uppercase;
        color: #b30437;
        font-size: 2rem;
    }

    section .quoteHighlight {
        display: block;
        margin: 1rem 2.25rem;
        font-size: 1.625rem;
        line-height: 1.875rem;
    }

    section .quoteHighlight2 {
        display: block;
        margin: 1rem 2.25rem;
        font-size: 2.125rem;
        line-height: 2rem;
    }

    section .quoteHighlight3 {
        display: block;
        margin: 1rem 2.25rem;
        font-size: 1.75rem;
        line-height: 2rem;
    }

    .split2Container {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .split2Container > * {
        width: 100%;
    }

    .split2Container > *:first-child {
        margin-right: 0;
    }

    .split2Container > *:nth-child(2) {
        margin-left: 0;
    }

    .splitColumns {
        column-count: 1;
        margin: 0 2.25rem;
    }

    footer h2 {
        font-size: 2.5rem;
    }

    footer #footer-info div {
        margin: 1rem 2.25rem;
    }

    footer .logos {
        flex-flow: row wrap;
        padding-left: 2.25rem;
    }

    footer .logos .logo {
        width: 50%;
        margin: 1rem 0;
    }

    footer .logos .logo img {
        max-width: 70%;
    }

    footer #headerphone {
        display: block;
        font-size: 2rem;
        margin: 2rem;
    }

    footer #footer-info h2 {
        display: none;
    }

    .section_end a {
        font-weight: 900;
        font-size: 1.875rem;
        line-height: 2rem;
    }

    .center-highlight {
        padding: 1.3rem;
    }

    .center-highlight .center-highlight-main {
        font-family: "Chivo", sans-serif;
        font-size: 0.8rem;
        line-height: 1.1rem;
        font-weight: 700;
        color: #737373;
    }

    .split2Container4-3 {
        flex-wrap: wrap;
    }

    .split2Container4-3 > *:first-child {
        width: 100%;
    }

    .split2Container4-3 > *:nth-child(2) {
        width: 100%;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .center-block {
        width: 52.25rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    section .quoteHighlight {
        font-size: 2.75rem;
        line-height: 3.125rem;
    }

    section .quoteHighlight2 {
        line-height: 3rem;
    }

    body.col_europe footer .center-block {
        width: 52.25rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}
