@charset "utf-8";

body.msoares {
    color: #ffffff;
    background-color: rgba(124, 0, 26, 0.8);
    background-image: url("/assets/media/images/hero_ms_background.jpg");
    background-size: contain;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

body.msoares section {
    position: relative;
    width: 33.5rem;
    left: 50%;
}

body.msoares section h2 {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: -0.72px;
    font-size: 4.5rem;
    color: #eaeaea;
    text-align: center;
    padding: 0 2rem 0 2rem;
    background-image: url("/assets/media/graphics/white_strike.png");
    background-repeat: no-repeat;
    background-position: bottom 0.5rem right 50%;
}

body.msoares section .date {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 3.25rem;
    letter-spacing: 0.52px;
    line-height: auto;
    text-align: center;
    margin-top: 0;
}

body.msoares section p {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    line-height: 1.7rem;
    font-size: 1.5rem;
    color: #eaeaea;
}

body.msoares section p.highlight {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    line-height: 3rem;
    font-size: 3.25rem;
    letter-spacing: -2px;
    line-height: 3.75rem;
    color: #eaeaea;
}

body.msoares section a {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    color: #eaeaea;
    text-decoration: none;
}

body.msoares section .divider {
    position: relative;
    width: 18.75rem;
    height: 1rem;
    margin: 6.25rem 0 2rem 0;
    background-image: url("/assets/media/graphics/white_strike.png");
    background-repeat: no-repeat;
    background-position: bottom 0.5rem right 50%;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    body.msoares section h2 {
        font-size: 4rem;
    }

    body.msoares section p {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    body.msoares section p.highlight {
        font-size: 2.625rem;
        line-height: 3rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body.msoares {
        background-position: bottom;
    }

    body.msoares section {
        width: 100%;
        left: 0;
    }

    body.msoares section h2 {
        font-size: 4rem;
    }

    body.msoares section p.date,
    body.msoares section p.ff {
        margin-left: 0;
        padding: 0 10%;
        width: 80%;
    }

    body.msoares section p,
    body.msoares section .divider {
        margin-left: 50%;
        width: 17.5rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    body.msoares {
        padding-top: 4rem;
        background-image: unset;
    }

    body.msoares section {
        box-sizing: border-box;
        width: 100%;
        padding: 0 2rem;
        left: 0;
    }

    body.msoares section h2 {
        font-size: 2.4rem;
    }

    body.msoares section p,
    body.msoares section .divider {
        width: 100%;
    }

    body.msoares section .date {
        font-size: 2.4rem;
    }
    body.msoares section p {
        font-size: 0.875rem;
        line-height: 1rem;
    }

    body.msoares section p.highlight {
        font-size: 1.875rem;
        line-height: 2rem;
    }

    body.msoares section .divider {
        margin-top: 2.5rem;
    }
}
