@charset "utf-8";

#hero-ms {
    position: relative;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

#hero-ms::after {
    content: "";
    /* background-image: url("/assets/media/images/hero_ms_background.jpg"); */
    background-image: url("/assets/media/graphics/hero_ms_background.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    /* background-color: rgba(150, 51, 71, 0.99); */
    background-color: rgba(124, 0, 26, 0.8);
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

#hero-ms .content {
    box-sizing: border-box;
    width: 100%;
    padding-left: 50%;
}

#hero-ms .content .content-inner {
    width: 33rem;
    text-align: left;
    color: #eaeaea;
}

#hero-ms .content .content-inner h2 {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: -0.72px;
    font-size: 4.5rem;
    color: #eaeaea;
    text-align: center;
    padding: 0 2rem 0 2rem;
    background-image: url("/assets/media/graphics/white_strike.png");
    background-repeat: no-repeat;
    background-position: bottom 0.5rem right 50%;
}

#hero-ms .content .content-inner .date {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 3.25rem;
    letter-spacing: 0.52px;
    line-height: auto;
    text-align: center;
    margin-top: 0;
}

#hero-ms .content .content-inner p {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    line-height: 1.7rem;
    font-size: 1.5rem;
    color: #eaeaea;
}

#hero-ms .content .content-inner p.highlight {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    line-height: 3rem;
    font-size: 3.25rem;
    letter-spacing: -2px;
    line-height: 3.75rem;
    color: #eaeaea;
}

#hero-ms .content .content-inner a {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    color: #eaeaea;
    text-decoration: none;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #hero-ms::after {
        left: -10rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #hero-ms::after {
        background-size: auto 100%;
        background-position: -14.5rem 0;
    }

    #hero-ms .content {
        padding-left: 24.5rem;
    }

    #hero-ms .content .content-inner {
        width: 19.5rem;
    }

    #hero-ms .content .content-inner h2 {
        font-size: 2.5rem;
    }

    #hero-ms .content .content-inner p {
        font-size: 1rem;
    }

    #hero-ms .content .content-inner p.highlight {
        font-size: 1.75rem;
        line-height: 2rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #hero-ms::after {
        background: unset;
        background-color: #7c001a;
    }

    #hero-ms .content {
        padding: 2.1rem;
    }

    #hero-ms .content .content-inner {
        width: 100%;
    }

    #hero-ms .content .content-inner h2 {
        font-size: 2.625rem;
        padding: 0;
        margin: 0;
    }

    #hero-ms .content .content-inner p {
        font-size: 0.8rem;
    }

    #hero-ms .content .content-inner p.highlight {
        font-size: 1.875rem;
        line-height: 2.5rem;
        letter-spacing: -1.2px;
    }

    #hero-ms .content .content-inner .date {
        margin-top: 1rem;
    }
}
