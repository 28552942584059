@charset "utf-8";

body.col_europe #hero {
    position: relative;
    box-sizing: border-box;
    min-height: 600px;
    height: 100vh;
    background-image: url("../media/images//hero_col_europe_background.jpg");
    background-size: cover;
}

body.col_europe #hero #heroTitle {
    position: absolute;
    bottom: 13.5rem;
    right: 7.125rem;
}

body.col_europe #hero #heroTitle span {
    position: relative;
    text-align: right;
    display: block;
}

body.col_europe #hero #heroTitle span.mario {
    font-family: "Archivo Black", sans-serif;
    font-size: 9.375rem;
    line-height: 12rem;
    letter-spacing: -6px;
    text-transform: uppercase;
    color: #b30437;
    font-weight: normal;
}

body.col_europe #hero #heroTitle span.soares {
    font-family: "Archivo", sans-serif;
    font-size: 13.125rem;
    line-height: 8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -8.4px;
    color: #ffffff;
}

body.col_europe #hero #heroTitle span.patrono {
    font-family: "Chivo", sans-serif;
    font-size: 3.75rem;
    line-height: 4.0625rem;
    letter-spacing: -2.4px;
    margin-top: 1.3rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #ffffff;
}

body.col_europe #hero #heroSocialBadges {
    position: absolute;
    bottom: 8.125rem;
    left: 8.125rem;
    z-index: 2;
    width: 100px;
    height: 30px;
    background-color: #ffffff;
    display: none;
}

body.col_europe #hero #heroContacts {
    position: absolute;
    bottom: 8.125rem;
    right: 8.125rem;
    z-index: 2;
    font-family: "Archivo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    display: none;
}

body.col_europe #hero #heroContacts a {
    text-decoration: none;
    color: #ffffff;
}

body.col_europe #hero #heroContacts a:hover,
body.col_europe #hero #heroContacts a:visited {
    text-decoration: none;
    color: #ffffff;
}

body.col_europe #hero #enterButton {
    position: absolute;
    display: block;
    text-align: center;
    border-radius: 1.5625rem;
    width: 17.5rem;
    height: 3.125rem;
    background-color: #b30437;
    line-height: 3.125rem;
    left: calc(100vw / 2 - 6.25rem);
    bottom: 8.125rem;
    font-family: "Chivo", sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
}

/* smartphones */
@media only screen and (max-width: 767px) {
    /* Styles */
    body.col_europe #hero {
        background-position: 30% 0;
        background-image: url("../media/images//hero_col_europe_background_phone.jpg");
    }

    body.col_europe #hero #heroTitle {
        bottom: 9.125rem;
        right: 2.375rem;
    }

    body.col_europe #hero #heroTitle span.mario {
        font-size: 3.125rem;
        line-height: 3.125rem;
        letter-spacing: -2px;
    }

    body.col_europe #hero #heroTitle span.soares {
        font-size: 4.375rem;
        letter-spacing: -2.75px;
        line-height: 4.375rem;
    }

    body.col_europe #hero #heroTitle span.patrono {
        font-size: 1.1875rem;
        line-height: 1.3125rem;
        letter-spacing: -1.3125px;
        margin-top: 0;
    }

    body.col_europe #hero #heroSocialBadges {
        bottom: 2.25rem;
        left: 2.25rem;
    }

    body.col_europe #hero #heroContacts {
        font-size: 0.75rem;
        bottom: 2.25rem;
        right: 2.25rem;
    }

    body.col_europe #hero #enterButton {
        font-size: 0.875rem;
        line-height: 2.5rem;
        width: 12.5rem;
        height: 2.5rem;
        bottom: 5rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body.col_europe #hero {
        background-position: 30% 0;
        background-image: url("../media/images//hero_col_europe_background_tablet.jpg");
    }

    body.col_europe #hero #heroTitle {
        bottom: 12.5rem;
        right: 0rem;
    }

    body.col_europe #hero #heroTitle span.mario {
        font-size: 6.25rem;
        line-height: 5rem;
        letter-spacing: -4px;
    }

    body.col_europe #hero #heroTitle span.soares {
        font-size: 8.875rem;
        line-height: 7.5rem;
        letter-spacing: -5.68px;
    }

    body.col_europe #hero #heroTitle span.patrono {
        font-size: 2.25rem;
        line-height: 2.6875rem;
        letter-spacing: -1.44px;
        margin-top: 0.3rem;
    }

    body.col_europe #hero #heroSocialBadges {
        display: none;
    }

    body.col_europe #hero #heroContacts {
        display: none;
    }

    body.col_europe #hero #enterButton {
        bottom: 3.75rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    body.col_europe #hero #heroTitle {
        top: unset;
        bottom: 4.375rem;
        right: 4rem;
    }

    body.col_europe #hero #heroTitle span.mario {
        font-size: 6.875rem;
        letter-spacing: -4.4px;
        line-height: 6.5rem;
    }

    body.col_europe #hero #heroTitle span.soares {
        font-size: 9.75rem;
        letter-spacing: -6.24px;
        line-height: 8rem;
    }

    body.col_europe #hero #heroTitle span.patrono {
        font-size: 2.5rem;
        font-weight: 200;
        letter-spacing: -1.6px;
        line-height: 3rem;
        margin-top: 0;
    }

    body.col_europe #hero #heroSocialBadges {
        bottom: 4rem;
        left: 4rem;
    }

    body.col_europe #hero #heroContacts {
        bottom: 4rem;
        right: 4rem;
    }

    body.col_europe #hero #enterButton {
        bottom: 4rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (max-height: 900px) and (min-width: 1440px) {
    body.col_europe #hero #heroTitle {
        top: unset;
        bottom: 9.375rem;
        right: 8.125rem;
    }

    body.col_europe #hero #heroTitle span.mario {
        font-size: 6.875rem;
        letter-spacing: -4.4px;
        line-height: 6.5rem;
    }

    body.col_europe #hero #heroTitle span.soares {
        font-size: 9.75rem;
        letter-spacing: -6.24px;
        line-height: 8rem;
    }

    body.col_europe #hero #heroTitle span.patrono {
        font-size: 2.5rem;
        font-weight: 200;
        letter-spacing: -1.6px;
        line-height: 3rem;
        margin-top: 0;
    }
}
