@charset "utf-8";

/* large desktop */
@media only screen and (min-width: 1024px) {
    body.europe_with_us #menu-call-container {
        /* background-color: unset; */
        /* position: absolute; */
    }
}

body.europe_with_us #menuOut #menuEntries a, #menuOut #menuEntries a:hover {
    color: #7C121C;
}

body.europe_with_us #menuOut #menuEntries ul li a {
    color: #737373;
}

/* HERO */
#ewu_hero {
    height: calc(100vh - 8rem);
}

#ewu_hero #hero_image {
    position: absolute;
    top: 0;
    width: 55%;
    height: 100%;
    background-image: url("../media/images/europa_connosco_hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

#ewu_hero #title {
    position: absolute;
    width: 35rem;
    margin: 12.5rem 0 0 50%;
    color: #7c121c;
    text-align: right;
    text-transform: uppercase;
}

#ewu_hero #title p {
    margin: -1rem 0 0 0;
}

#ewu_hero #title img {
    width: 21rem;
}

#ewu_hero #title #europa {
    font-family: "Oswald", sans-serif;
    font-size: 8.125rem;
    font-weight: 400;
    letter-spacing: -5.2px;
    line-height: 8.2rem;
}

#ewu_hero #title #connosco {
    font-family: "Oswald", sans-serif;
    font-size: 8.125rem;
    font-weight: 700;
    letter-spacing: -5.2px;
    line-height: 8.2rem;
}

/* HERO TEXT */
#ewu_hero_text {
    background-color: #7c121c;
    color: #fff;
}

#ewu_hero_text .content {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 45rem;
    margin: 0 auto;
    font-size: 1.75rem;
}

#ewu_hero_text .content p {
    margin: 0;
}

/* HERO VIDEO */
#ewu_hero_video {
    background-color: #afafaf;
    color: #fff;
}

#ewu_hero_video .content {
    width: 52.5rem;
    margin: 0 auto;
}

#ewu_hero_video h2 {
    font-family: "Oswald", sans-serif;
    font-size: 2.625rem;
    font-family: 2.625rem;
    font-weight: 700;
    color: #fff;
}

#ewu_hero_video_embed {
    width: 840px;
    height: 472px;
}

/* HERO  EXHIBIT */
#ewu_hero_exhibit {
    background-color: #eaeaea;
}

#ewu_hero_exhibit .content {
    width: 52.5rem;
    margin: 0 auto;
}

#ewu_hero_exhibit .content h2 {
    font-family: "Oswald", sans-serif;
    font-size: 2.625rem;
    font-family: 2.625rem;
    font-weight: 700;
    color: #7c121c;
}

#ewu_hero_exhibit p {
    font-family: "Chivo", sans-serif;
    font-size: 1.125rem;
}

#ewu_hero_exhibit #enterButton {
    display: block;
    margin: 0 auto;
    text-align: center;
    border-radius: 1.5625rem;
    width: 17.5rem;
    height: 3.125rem;
    background-color: #7c121c;
    line-height: 3.125rem;
    left: calc(50vw - 6.25rem);
    bottom: 8.125rem;
    font-family: Chivo, sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
}

/* HERO VISIT */
#ewu_hero_visit {
    background-color: #7c121c;
    color: #fff;
}

#ewu_hero_visit a {
    color: #fff;
}

#ewu_hero_visit .content {
    width: 52.5rem;
    margin: 0 auto;
}

#ewu_hero_visit .content h2 {
    font-family: "Oswald", sans-serif;
    font-size: 2.625rem;
    font-family: 2.625rem;
    font-weight: 700;
    color: #fff;
    margin-top: 0;
}

#ewu_hero_visit .content p {
    margin: 2.5rem 0;
}

#ewu_hero_visit .highlight1 {
    font-size: 1.2rem;
}

#ewu_hero_visit .highlight2 {
    font-size: 1.125rem;
    font-weight: 700;
}

.europe_with_us footer,
.europe_with_us_exhibit footer {
    background-color: #fff;
}

.europe_with_us footer .center-block,
.europe_with_us_exhibit footer .center-block {
    width: 60rem;
}

.europe_with_us footer .support_logos,
.europe_with_us_exhibit footer .support_logos {
    text-align: right;
    flex-grow: 1;
}

/* small desktop */
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    #ewu_hero #title {
        margin: 12.5rem 0 0 44%;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body.europe_with_us #menu-call-container {
        background-color: rgba(234, 234, 234, 0.8);
    }

    #ewu_hero #hero_image {
        position: absolute;
        top: 15%;
        left: 5%;
        width: 55%;
        height: 70%;
        background-image: url("../media/images/europa_connosco_hero.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.7;
    }

    #ewu_hero #title {
        position: absolute;
        width: 80%;
        margin: 12.5rem 10% 0 10%;
        color: #7c121c;
        text-align: right;
        text-transform: uppercase;
    }

    #ewu_hero #title p {
        margin: -1rem 0 0 0;
    }

    #ewu_hero #title img {
        width: 21rem;
    }

    #ewu_hero #title #europa {
        font-family: "Oswald", sans-serif;
        font-size: 8.125rem;
        font-weight: 400;
        letter-spacing: -5.2px;
        line-height: 8.2rem;
    }

    #ewu_hero #title #connosco {
        font-family: "Oswald", sans-serif;
        font-size: 8.125rem;
        font-weight: 700;
        letter-spacing: -5.2px;
        line-height: 8.2rem;
    }

    /* HERO TEXT */
    #ewu_hero_text {
        background-color: #7c121c;
        color: #fff;
    }

    #ewu_hero_text .content {
        font-family: "Chivo", sans-serif;
        font-weight: 700;
        text-align: center;
        width: 22rem;
        margin: 0 auto;
        font-size: 1rem;
    }

    #ewu_hero_text .content p {
        margin: 0;
    }

    .europe_with_us footer .center-block,
    .europe_with_us_exhibit footer .center-block {
        width: 80%;
        padding: 0;
    }

    #ewu_hero_video .content {
        width: 36rem;
    }

    #ewu_hero_video_embed {
        width: 36rem;
    }

    #ewu_hero_exhibit .content {
        width: 36rem;
    }

    #ewu_hero_exhibit .content .split2Container p {
        font-size: 0.9rem;
    }

    #ewu_hero_visit .content {
        width: 36rem;
    }

    #ewu_hero_visit .split2Container {
        flex-direction: column;
    }

    #ewu_hero_visit .split2Container div:first-child {
        order: 2;
    }

    #ewu_hero_visit .split2Container div {
        width: 100%;
    }

    .europe_with_us footer .logo_fmsmb img,
    .europe_with_us_exhibit footer .logo_fmsmb img {
        width: 5.625rem;
    }

    .europe_with_us footer .support_logos img,
    .europe_with_us_exhibit footer .support_logos img {
        width: 25rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    body.europe_with_us #menu-call-container {
        background-color: rgba(234, 234, 234, 0.8);
    }

    body.europe_with_us h2 {
        margin: 0;
    }

    body.europe_with_us .content .split2Container p {
        margin-left: 0;
        margin-right: 0;
    }

    #ewu_hero {
        height: 29rem;
    }

    #ewu_hero #hero_image {
        position: absolute;
        top: 10%;
        left: 5%;
        width: 60%;
        height: 50%;
        background-image: url("../media/images/europa_connosco_hero.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.7;
    }

    #ewu_hero #title {
        position: absolute;
        width: 80%;
        margin: 11.5rem 0 0 12%;
        color: #7c121c;
        text-align: right;
        text-transform: uppercase;
    }

    #ewu_hero #title p {
        margin: -1rem 0 0 0;
    }

    #ewu_hero #title img {
        width: 10rem;
    }

    #ewu_hero #title #europa {
        font-family: "Oswald", sans-serif;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: -2.56px;
        line-height: 4rem;
    }

    #ewu_hero #title #connosco {
        font-family: "Oswald", sans-serif;
        font-size: 4rem;
        font-weight: 700;
        letter-spacing: -2.56px;
        line-height: 3rem;
    }

    /* HERO TEXT */
    #ewu_hero_text {
        background-color: #7c121c;
        color: #fff;
    }

    #ewu_hero_text .content {
        font-family: "Chivo", sans-serif;
        font-weight: 700;
        text-align: center;
        width: 22rem;
        margin: 0 auto;
        font-size: 1rem;
    }

    #ewu_hero_text .content p {
        margin: 0;
    }

    .europe_with_us footer .center-block,
    .europe_with_us_exhibit footer .center-block {
        width: 80%;
        padding: 0;
    }

    #ewu_hero_video .content {
        width: 85%;
    }

    #ewu_hero_video_embed {
        width: 100%;
        height: 300px;
    }

    #ewu_hero_exhibit .content {
        width: 85%;
    }

    #ewu_hero_exhibit .content .split2Container p {
        font-size: 0.9rem;
    }

    #ewu_hero_visit .content {
        width: 85%;
    }

    #ewu_hero_visit .split2Container {
        flex-direction: column;
    }

    #ewu_hero_visit .split2Container div:first-child {
        order: 2;
    }

    #ewu_hero_visit .split2Container div {
        width: 100%;
    }

    .europe_with_us footer #footer-info div,
    .europe_with_us_exhibit footer #footer-info div {
        margin-left: 0;
        margin-right: 1rem;
    }

    .europe_with_us footer .logo_fmsmb img,
    .europe_with_us_exhibit footer .logo_fmsmb img {
        width: 5.625rem;
    }

    .europe_with_us footer .support_logos img,
    .europe_with_us_exhibit footer .support_logos img {
        width: 100%;
    }
}
