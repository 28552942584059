@charset "utf-8";

#fmsmb {
    background-image: url("/assets/media/images/fmsmb_background.jpg");
    background-size: cover;
    background-position: top;
    min-height: 100vh;
}

#fmsmb h2 {
    font-family: "Oswald", sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #7c001a;
}

#fmsmb p.red_strong {
    font-weight: 700;
    color: #7c001a;
}

#fmsmb p.red {
    color: #7c001a;
}

#fmsmb img.fmsmb_lisboa {
    max-width: 100%;
    box-shadow: 0px 0px 20px -2px rgba(138, 136, 136, 1);
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #fmsmb h2 {
        font-size: 4rem;
    }

    #fmsmb p {
        font-size: 1.25rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #fmsmb h2 {
        font-size: 4rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #fmsmb h2 {
        margin: 0 auto;
        text-align: center;
        font-size: 2.625rem;
    }
}
