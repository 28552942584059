@charset "utf-8";

#contents {
    position: relative;
    display: flex;
    box-sizing: border-box;
    background-color: #eaeaea;
    justify-content: center;
}

#contents .content {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 12.5rem;
}

#contents .content .part {
    display: flex;
    flex-direction: column;
    width: 31.25rem;
    margin: 0 1rem;
    height: 37.5rem;
    text-align: center;
}

#contents .content .part a {
    width: 100%;
    display: flex;
    flex-grow: 1;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

#contents .content .part .graphic {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

#contents .content .part .graphic .title {
    font-family: "Oswald", sans-serif;
    font-size: 3.25rem;
    letter-spacing: -2px;
    line-height: 3.75rem;
    color: #1f3e8e;
}

#contents .content .part .graphic.chronology .title {
    color: #7c001a;
}

#contents .content .part .graphic .info {
    font-family: "Chivo", sans-serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #1d1d1b;
    margin: 1rem 5rem;
}

#contents .content .part .footnote {
    height: 4.5rem;
    line-height: 4.5rem;
    color: #1f3e8e;
    background-color: #ffffff;
}

#contents .content .part .footnote a {
    font-family: "Chivo", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    color: #1f3e8e;
}

#contents .content .part .graphic.collection {
    background-image: url("/assets/media/images/collection.jpg");
    background-position: center;
    background-size: cover;
}

#contents .content .part .graphic.chronology {
    background-image: url("/assets/media/images/chronology.jpg");
    background-position: center;
    background-size: cover;
}

#contents .content .part .graphic.bibliography {
    background-image: url("/assets/media/images/bibliography.jpg");
    background-position: center;
    background-size: cover;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #contents .content {
        margin: 9.25rem;
    }

    #contents .content .part {
        width: 21.875rem;
        height: 31rem;
        margin: 0 0.6rem;
    }

    #contents .content .part .graphic .title {
        font-family: "Oswald", sans-serif;
        font-size: 2.25rem;
        letter-spacing: -1.44px;
        line-height: 2.625rem;
        color: #1f3e8e;
    }

    #contents .content .part .graphic .info {
        font-family: "Chivo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: #1d1d1b;
        margin: 1rem 3rem;
    }

    #contents .content .part .footnote a {
        font-size: 1rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #contents {
        padding: 3.125rem 6rem;
    }

    #contents .content {
        flex-direction: column;
        margin: 0;
    }

    #contents .content .part {
        margin: 0.625rem 0;
        height: 15.625rem;
    }

    #contents .content .part .graphic.chronology {
        background-position-y: 20%;
    }

    #contents .content .part .graphic.bibliography {
        background-position-y: 18%;
    }

    #contents .content .part .graphic .title {
        font-size: 2rem;
    }

    #contents .content .part .graphic .info {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    #contents .content .part .footnote {
        height: 2.8125rem;
        line-height: 2.8125rem;
    }

    #contents .content .part .footnote a {
        font-size: 0.875rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #contents {
        padding: 3.125rem 2rem;
    }

    #contents .content {
        flex-direction: column;
        width: 100%;
        margin: 0;
    }

    #contents .content .part {
        margin: 0.625rem 0;
        height: 15.625rem;
        width: 100%;
    }

    #contents .content .part .graphic.chronology {
        background-position-y: 20%;
    }

    #contents .content .part .graphic.bibliography {
        background-position-y: 18%;
    }

    #contents .content .part .graphic .title {
        font-size: 2rem;
    }

    #contents .content .part .graphic .info {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    #contents .content .part .footnote {
        height: 2.8125rem;
        line-height: 2.8125rem;
    }

    #contents .content .part .footnote a {
        font-size: 0.875rem;
    }
}
