@charset "utf-8";

body.main-page {
    background-image: url("../media/images/hero_background.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: right;
}

#hero {
    position: relative;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    min-height: 600px;
    height: 100vh;
    /* background-image: url("../media/images/hero_background.jpg");
    background-size: cover; */
}

#hero #heroTitle {
    position: relative;
    align-self: center;
    text-align: center;
    font-family: "Oswald", sans-serif;
    font-size: 7.25rem;
    font-weight: 700;
    letter-spacing: -4.64px;
    text-transform: uppercase;
    color: #7c001a;
    margin: 0;
}

#hero #heroTitle span {
    position: relative;
    top: -3rem;
}

#hero #heroSocialBadges {
    position: absolute;
    bottom: 8.125rem;
    left: 8.125rem;
    z-index: 2;
    width: 100px;
    height: 30px;
    background-color: #ffffff;
    display: none;
}

#hero #heroContacts {
    position: absolute;
    bottom: 8.125rem;
    right: 8.125rem;
    z-index: 2;
    font-family: "Archivo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    display: none;
}

#hero #heroContacts a {
    text-decoration: none;
    color: #ffffff;
}

#hero #heroContacts a:hover,
#hero #heroContacts a:visited {
    text-decoration: none;
    color: #ffffff;
}

#hero #enterButton {
    position: absolute;
    display: block;
    left: calc(100vw / 2);
    bottom: 7rem;
}

/* smartphones */
@media only screen and (max-width: 767px) {
    body.main-page {
        background-image: unset;
        background-attachment: unset;
        background-size: unset;
    }

    #hero {
        background-image: url("../media/images/hero_background.jpg");
        background-size: cover;
        background-position: right -7rem bottom 0;
    }

    #hero #heroTitle {
        align-self: flex-start;
        margin: 0 2.5rem;
        font-size: 3.125rem;
        letter-spacing: -2px;
    }

    #hero #heroTitle span {
        top: -1rem;
    }

    #hero #heroTitle img {
        max-width: 100%;
    }

    #hero #heroSocialBadges {
        bottom: 2.25rem;
        left: 2.25rem;
    }

    #hero #heroContacts {
        font-size: 0.75rem;
        bottom: 2.25rem;
        right: 2.25rem;
    }

    #hero #enterButton {
        left: calc(100vw / 2 - 1.5625rem);
    }

    #hero #enterButton img {
        width: 3.125rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body.main-page {
        background-image: unset;
        background-attachment: unset;
        background-size: unset;
    }

    #hero {
        background-image: url("../media/images/hero_background.jpg");
        background-size: cover;
        background-position: right -7rem bottom 0;
    }

    #hero #heroTitle {
        align-self: flex-start;
        margin: 0 6rem;
        font-size: 6.25rem;
    }

    #hero #heroTitle span {
        top: -1rem;
    }

    #hero #heroTitle img {
        max-width: 100%;
    }

    #hero #heroSocialBadges {
        display: none;
    }

    #hero #heroContacts {
        display: none;
    }

    #hero #enterButton {
        bottom: 3.75rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #hero #heroSocialBadges {
        bottom: 4rem;
        left: 4rem;
    }

    #hero #heroContacts {
        bottom: 4rem;
        right: 4rem;
    }

    #hero #enterButton {
        bottom: 4rem;
    }
}
