@charset "utf-8";

#bibliography,
#collection {
    background-image: url("../media/images/bibliography_page_background.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

#bibliography h1,
#collection h1 {
    font-family: "Oswald", sans-serif;
    color: #1f3e8e;
    font-size: 3.25rem;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    margin-top: 3.75rem;
}

.section-info {
    font-family: "Chivo", sans-serif;
    font-size: 1.5rem;
    width: 47.5rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

#filters {
    display: flex;
    flex-wrap: wrap;
    width: 77rem;
    max-width: 100%;
    margin: 0 auto;
    justify-content: center;
    margin-top: 1.2rem;
    margin-bottom: 2.875rem;
}

#filters a {
    height: 2.875rem;
    line-height: 2.875rem;
    padding: 0 2.5rem;
    margin: 0 1.25rem;
    text-decoration: none;
    font-family: "Chivo", sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    color: #7c001a;
    border: 2px solid #7c001a;
    border-radius: 1.4375rem;
    text-transform: uppercase;
}

#filters a[data-iso-filter="mse"] {
    color: #1f3e8e;
    border: 2px solid #1f3e8e;
}

#filters a:hover,
#filters a.selected {
    color: #ffffff;
    background-color: #7c001a;
}

#filters a[data-iso-filter="mse"]:hover,
#filters a[data-iso-filter="mse"].selected {
    color: #ffffff;
    background-color: #1f3e8e;
}

#books,
#documents {
    display: flex;
    flex-wrap: wrap;
    width: 77rem;
    margin: 0 auto;
}

.book,
.doc {
    position: relative;
    box-sizing: border-box;
    width: 16.25rem;
    margin: 2rem 1.5rem;
    background-color: #ffffff;
    padding: 0.625rem;
    overflow: visible;
}

.book .euro_seal {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: 3rem;
    height: 3rem;
    background-image: url("/assets/media/graphics/euro_seal.png");
    background-size: contain;
}

.book img,
.doc img {
    border-radius: 0.5rem;
    max-width: 100%;
}

.book .title,
.doc .title {
    display: block;
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #737373;
}

.book .author {
    display: block;
    font-family: "Chivo", sans-serif;
    font-style: italic;
    font-size: 1.1rem;
    line-height: 1.25rem;
    margin: 0.4rem 0;
    color: #1d1d1b;
}

.book .publisher,
.book .local,
.book .year,
.doc .date {
    font-family: "Chivo", sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #737373;
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #bibliography {
        background-position: unset;
    }

    #bibliography h1 {
        font-size: 2.625rem;
        letter-spacing: -1.68px;
    }

    .section-info {
        box-sizing: border-box;
        font-size: 1rem;
        width: 100%;
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }

    #filters {
        box-sizing: border-box;
        width: 100%;
        padding-left: 2.25rem;
        padding-right: 2.25rem;
        flex-direction: column;
    }

    #filters a {
        margin: 0.5rem;
        text-align: center;
        font-size: 0.6rem;
        padding: 0 0.5rem;
    }

    #books,
    #documents {
        width: 100%;
        padding-left: 2.25rem;
        padding-right: 2.25rem;
        box-sizing: border-box;
    }

    .book,
    .doc {
        margin: 0 0;
        padding: 1rem 0.625rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .section-info {
        box-sizing: border-box;
        font-size: 1.25rem;
        width: 100%;
        padding-left: 6rem;
        padding-right: 6rem;
    }

    #filters {
        box-sizing: border-box;
        width: 100%;
        padding-left: 6rem;
        padding-right: 6rem;
        flex-direction: column;
    }

    #filters a {
        margin: 0.5rem;
        text-align: center;
    }

    #books,
    #documents {
        width: 100%;
        padding-left: 6rem;
        padding-right: 6rem;
        box-sizing: border-box;
    }

    .book,
    .doc {
        width: 15rem;
    }

    .book .title {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #bibliography h1,
    #collection h1 {
        margin-top: 0;
    }

    #books {
        width: 54.375rem;
    }

    .book {
        width: 15rem;
    }

    .book .title {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    #filters a {
        margin: 0 0.5rem;
    }
}
