@charset "utf-8";

#exhibitions {
    position: relative;
    display: block;
    box-sizing: border-box;
    text-align: center;
    background-color: #ffffff;
    padding: 12.5rem;
}

#exhibitions h2 {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: -0.72px;
    font-size: 4.5rem;
    color: #7c001a;
    margin: 0 auto;
}

#exhibitions h2.red_strike {
    padding: 0 2rem 0 2rem;
    background-image: url("/assets/media/graphics/red_strike.png");
    background-repeat: no-repeat;
    background-position: bottom 0.5rem right 50%;
}

#exhibitions .content {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

#exhibitions .content .exhibition {
    position: relative;
    width: 42.875rem;
    margin: 0 1rem;
    text-align: left;
}

#exhibitions .content .exhibition a {
    color: unset;
    text-decoration: none;
}

#exhibitions .content .exhibition img {
    max-width: 100%;
}

#exhibitions .content .exhibition .brevemente {
    position: absolute;
    display: block;
    width: 100%;
    top: 10.25rem;
    text-align: center;
    font-family: "Chivo", sans-serif;
    font-weight: 900;
    font-size: 3.375rem;
    line-height: 3.375rem;
    text-transform: uppercase;
    color: #afafaf;
}

#exhibitions .content .exhibition .title {
    font-family: "Oswald", sans-serif;
    font-size: 3.25rem;
    line-height: 3.75rem;
    letter-spacing: -2px;
    margin-top: 2rem;
    text-transform: uppercase;
    color: #1d1d1b;
}

#exhibitions .content .exhibition .dates {
    font-weight: 700;
    font-size: 1.5rem;
    color: #737373;
    line-height: 2.25rem;
}

#exhibitions .content .exhibition .info {
    color: #737373;
    font-size: 1.5rem;
    line-height: 2.25rem;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #exhibitions {
        padding: 6rem;
    }

    #exhibitions .content .exhibition {
        width: 33.4375rem;
        margin: 0 0.5rem;
    }

    #exhibitions .content .exhibition .title {
        font-size: 2.5rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #exhibitions {
        padding: 6rem;
    }

    #exhibitions .content {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 2rem 0;
    }

    #exhibitions .content .exhibition {
        width: 100%;
        margin: 1.9375rem 0;
    }

    #exhibitions .content .exhibition .title {
        font-size: 2.5rem;
        margin-top: 0.5rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #exhibitions {
        padding: 2rem;
    }

    #exhibitions h2 {
        font-size: 2.6rem;
    }

    #exhibitions .content {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 2rem 0;
    }

    #exhibitions .content .exhibition {
        width: 100%;
        margin: 0.875rem 0;
    }

    #exhibitions .content .exhibition .brevemente {
        font-size: 1.5rem;
        line-height: 1.5rem;
        top: 5rem;
    }

    #exhibitions .content .exhibition .title {
        font-size: 2rem;
        line-height: 2.2rem;
        margin-top: 0.5rem;
    }

    #exhibitions .content .exhibition .dates,
    #exhibitions .content .exhibition .info {
        font-size: 1rem;
        line-height: 1.25rem;
    }
}
