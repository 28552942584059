@charset "utf-8";

#verticalNav {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    top: 0rem;
    bottom: 0rem;
    right: 8.125rem;
    z-index: 2;
    font-family: "Chivo", sans-serif;
    font-weight: 700;
}

#verticalNav.hidden {
    display: none;
}

#verticalNav a {
    display: block;
    margin-top: 1rem;
    text-decoration: none;
}

#verticalNav a .vNavHome {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background: url("/assets/media/graphics/home.png") no-repeat;
    background-size: contain;
}

#verticalNav a .vNavText {
    display: none;
    position: relative;
    font-weight: 400;
    height: 1.2rem;
    padding: 0 0.7rem;
    border-radius: 0.6rem;
    top: -0.26rem;
    color: #fff;
    background-color: #b30437;
}

#verticalNav a:hover .vNavText {
    display: inline-block;
}

#verticalNav a .vNavCircle {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background: url("/assets/media/graphics/nav_dot.png") no-repeat;
    background-size: contain;
}

#verticalNav a.selected .vNavCircle {
    background: url("/assets/media/graphics/nav_dot_selected.png") no-repeat;
    background-size: contain;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #verticalNav {
        right: 4rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #verticalNav {
        right: 1.5rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #verticalNav {
        display: none;
    }
}
