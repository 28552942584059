@charset "utf-8";

#menu-call-container {
    position: fixed;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    align-items: center;
    top: 8.125rem;
    left: 0;
    padding-left: 8.125rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    z-index: 2;
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: #7c001a;
    z-index: 91;
}

body.menu #menu-call-container,
body.msoares #menu-call-container {
    background-color: unset;
}

#menu-call-container a {
    display: flex;
    line-height: 2.5rem;
    align-items: center;
    color: #7c001a;
    text-decoration: none;
}

#menu-call-container a.blue {
    color: #1f3e8e;
}

#menu-call-container a.white {
    color: #ffffff;
}

.main-page #menu-call-container img {
    display: none;
}

#menu-call-container img {
    height: 100%;
    padding: 0 2rem 0 0;
}

#menuOut {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 90;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}

body.menu .menu-call::before {
    content: "X\00a0";
}

#menuOut #menuEntries {
    position: relative;
    margin: 0 auto;
    padding: 0;
    list-style: none;
}

#menuOut #menuEntries ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
}

#menuOut #menuEntries ul li {
    line-height: 2rem;
}

#menuOut #menuEntries ul li a {
    font-size: 2rem;
    color: #737373;
}

#menuOut #menuEntries li {
    font-family: "Oswald";
    font-weight: 500;
    font-size: 2.75rem;
    line-height: 3.5rem;
    letter-spacing: 2.64px;
    text-transform: uppercase;
    color: #fff;
    margin: 0.7rem;
}

#menuOut #menuEntries li.team {
    font-family: "Oswald";
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 4.7rem;
    letter-spacing: unset;
    text-transform: uppercase;
    color: #fff;
    margin: 0.7rem;
}

#menuOut #menuEntries a {
    text-decoration: none;
}

#menuOut #menuEntries a,
#menuOut #menuEntries a:hover {
    color: #1f3e8e;
}

#menuOut #menuEntries li.team a,
#menuOut #menuEntries li.team a:hover {
    color: #737373;
}

#langSelection {
    position: absolute;
    top: 8.125rem;
    right: 8.125rem;
    font-size: 1.5rem;
    z-index: 2;
    font-family: "Chivo", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #7c001a;
    z-index: 92;
}

#langSelection a {
    color: #7c001a;
    text-decoration: none;
}

#langSelection a.selected {
    font-weight: 700;
}

#langSelection.blue,
#langSelection.blue a {
    color: #1f3e8e;
}

#langSelection.white,
#langSelection.white a {
    color: #ffffff;
}

body.menu #menuOut {
    display: flex;
}

body.menu #menu-call-container {
    color: #b30437;
}

body.menu {
    overflow: hidden;
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #menu-call-container {
        box-sizing: border-box;
        padding: 0.5rem 1.5rem;
        width: 100%;
        font-size: 1rem;
        top: 0;
        left: 0;
    }

    body.msoares #menu-call-container {
        background-color: #7c001a;
    }

    #menuOut #menuEntries li,
    #menuOut #menuEntries ul li a {
        font-size: 1.75rem;
        line-height: 2rem;
    }

    #menuOut #menuEntries ul li a {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    #langSelection {
        position: fixed;
        top: 1.5rem;
        right: 1.5rem;
        font-size: 1rem;
        line-height: 1rem;
    }

    #menuOut #menuEntries li {
        font-size: 1.5rem;
        padding: 0 2.25rem;
    }

    #menuOut #menuEntries ul li {
        font-size: 1rem;
        padding: 0;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #menu-call-container {
        padding: 1.5rem;
        width: 100%;
        font-size: 1rem;
        top: 0;
        left: 0;
    }

    body.msoares #menu-call-container {
        background-color: #7c001a;
    }

    #menuOut #menuEntries li,
    #menuOut #menuEntries ul li a {
        font-size: 1.75rem;
        line-height: normal;
    }

    #menuOut #menuEntries ul li a {
        font-size: 1.25rem;
        line-height: normal;
    }

    #langSelection {
        position: fixed;
        top: 1.5rem;
        right: 1.5rem;
        font-size: 1rem;
        line-height: 1rem;
    }

    #menuOut #menuEntries li {
        font-size: 1.5rem;
        padding: 0 2.25rem;
    }

    #menuOut #menuEntries ul li {
        font-size: 1rem;
        padding: 0;
    }

    #menuOut #menuEntries ul {
        /* margin-top: -1.5rem; */
    }

    #langSelection {
        position: fixed;
        top: 1.5rem;
        right: 1.5rem;
        font-size: 1rem;
        line-height: 1.875rem;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #menu-call-container {
        top: 4rem;
        left: 0;
        padding-left: 4rem;
        padding-right: 1rem;
    }

    #menuOut #menuEntries li,
    #menuOut #menuEntries ul li a {
        font-size: 1.75rem;
    }

    #menuOut #menuEntries ul {
        margin-top: -1rem;
    }

    #langSelection {
        top: 4rem;
        right: 4rem;
    }
}
