@charset "utf-8";

body.europe_with_us_exhibit .bigQuote {
    font-family: "Chivo", sans-serif;
    color: #030504;
}

body.europe_with_us_exhibit .bigQuote.bigQuote_red .bigQuote_header,
body.europe_with_us_exhibit .bigQuote.bigQuote_red .bigQuote_main p {
    color: #7c121c;
}

body.europe_with_us_exhibit .bigQuote .bigQuote_header {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 6rem;
    line-height: 5rem;
    margin: 2rem 0 -2rem 0;
}

body.europe_with_us_exhibit .bigQuote .bigQuote_main p {
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 2.375rem;
    line-height: 2.375rem;
    margin-bottom: 1rem;
}

body.europe_with_us_exhibit .bigQuote .bigQuote_footer p {
    font-family: "Chivo", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: normal;
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body.europe_with_us_exhibit .bigQuote .bigQuote_main p {
        font-size:1.5rem;
        line-height: normal;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    body.europe_with_us_exhibit .bigQuote .bigQuote_main p {
        font-size:1.25rem;
        line-height: normal;
    }

    body.europe_with_us_exhibit .bigQuote .bigQuote_footer p {
        font-family: "Chivo", sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: normal;
    }
}
