@charset "utf-8";

#team {
    background-image: url("/assets/media/images/hero_team.jpg");
    /* background-size: auto 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 100vh;
    padding-top: 8.125rem;
}

#team h1 {
    font-family: "Oswald", sans-serif;
    color: #1f3e8e;
    font-size: 2.75rem;
    text-transform: uppercase;
    margin: 2rem auto;
    text-align: center;
    font-weight: 500;
}

#team .section-info {
    width: 100%;
    text-align: left;
}

#team p.red_strong {
    font-weight: 700;
    color: #7c001a;
}

#team p.red {
    color: #7c001a;
}

#team img.fmsmb_lisboa {
    max-width: 100%;
    box-shadow: 0px 0px 20px -2px rgba(138, 136, 136, 1);
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #team h1 {
        font-size: 2rem;
    }

    #team p {
        font-size: 1rem;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #team h2 {
        font-size: 4rem;
    }
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #team h2 {
        margin: 0 auto;
        text-align: center;
        font-size: 2.625rem;
    }
}
