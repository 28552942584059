@charset "utf-8";

#crono {
    background-image: url("../media/images/bibliography_page_background.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

#crono h1 {
    font-family: "Oswald", sans-serif;
    color: #7c001a;
    font-size: 3.25rem;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    margin-top: 3.75rem;
}

#crono-container {
    position: relative;
    width: 100%;
    margin-top: 6rem;
    background-color: rgba(115, 115, 115, 0.7);
}

#crono-container section {
    position: relative;
    z-index: 10;
}

#crono-container-line {
    position: absolute;
    width: 100%;
    top: 4rem;
    bottom: 4rem;
    background-image: url("/assets/media/graphics/linha_crono.png");
    background-repeat: repeat-y;
    background-position-x: center;
    z-index: 9;
}

#crono-container .center-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 78rem;
    z-index: 10;
}

#crono-container .year {
    width: 100%;
    margin: 3.125rem 0;
}

#crono-container .year_inner {
    margin: 0 auto;
    width: 9.375rem;
    height: 9.375rem;
    border-radius: 4.6875rem;
    line-height: 9.375rem;
    text-align: center;
    background-color: #ffffff;
    color: #7c001a;
    font-family: "Oswald", sans-serif;
    font-size: 3.25rem;
}

#crono-container .crono_entry {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 24rem;
    height: 25rem;
    padding: 1.375rem 1.375rem 0 1.375rem;
    background-color: #eaeaea;
    margin: 0.625rem;
    overflow: hidden;
}

#crono-container .crono_entry.with_image {
    background-color: #7c001a;
    color: #ffffff;
}

#crono-container .crono_entry .image_highlight {
    width: 100%;
    height: 11.25rem;
    margin-bottom: 1.25rem;
    background-repeat: no-repeat;
    background-size: cover;
}

#crono-container .crono_entry a {
    color: #737373 !important;
}

#crono-container .crono_entry.with_image .entry_content a {
    color: #ffffff !important;
}

#crono-container .crono_entry .date {
    display: block;
    font-family: "Oswald", sans-serif;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
}

#crono-container .crono_entry .entry_content {
    font-family: "Chivo", sans-serif;
    position: relative;
    color: #737373;
    text-overflow: ellipsis;
    overflow: hidden;
}

#crono-container .crono_entry.with_image .entry_content {
    color: #ffffff;
}

#crono-container .crono_entry::after {
    content: "";
    width: 100%;
    height: 4rem;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(rgba(234, 234, 234, 0), rgba(234, 234, 234, 1));
}

#crono-container .crono_entry.with_image::after {
    content: "";
    width: 100%;
    height: 4rem;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(rgba(124, 0, 26, 0), #7c001a);
}

/* smartphones */
@media only screen and (max-width: 767px) {
    #crono {
        background-position: unset;
    }

    #crono h1 {
        font-size: 2.625rem;
        letter-spacing: -1.68px;
    }

    #crono-container .center-block {
        box-sizing: border-box;
        width: 100%;
        padding: 0 2rem;
    }

    #crono-container .crono_entry {
        height: 18.75rem;
        /*
        height: unset;
        max-height: 25rem;
        padding-bottom: 4rem;
        */
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #crono-container .center-block {
        box-sizing: border-box;
        width: 100%;
        padding: 0 6rem;
    }

    #crono-container .crono_entry {
        height: 18.75rem;
        /*
        height: unset;
        max-height: 25rem;
        padding-bottom: 4rem;
        */
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #crono h1 {
        margin-top: 0;
    }

    #crono-container .center-block {
        width: 68rem;
    }
}
