@charset "utf-8";

/* archivo-regular - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Archivo Regular"), local("Archivo-Regular"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-regular.svg#Archivo")
            format("svg"); /* Legacy iOS */
}
/* archivo-italic - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: italic;
    font-weight: 400;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
    src: local("Archivo Italic"), local("Archivo-Italic"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-italic.svg#Archivo")
            format("svg"); /* Legacy iOS */
}
/* archivo-500 - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500.eot"); /* IE9 Compat Modes */
    src: local("Archivo Medium"), local("Archivo-Medium"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500.svg#Archivo") format("svg"); /* Legacy iOS */
}
/* archivo-500italic - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: italic;
    font-weight: 500;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500italic.eot"); /* IE9 Compat Modes */
    src: local("Archivo Medium Italic"), local("Archivo-MediumItalic"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-500italic.svg#Archivo")
            format("svg"); /* Legacy iOS */
}
/* archivo-600 - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600.eot"); /* IE9 Compat Modes */
    src: local("Archivo SemiBold"), local("Archivo-SemiBold"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600.svg#Archivo") format("svg"); /* Legacy iOS */
}
/* archivo-600italic - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: italic;
    font-weight: 600;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600italic.eot"); /* IE9 Compat Modes */
    src: local("Archivo SemiBold Italic"), local("Archivo-SemiBoldItalic"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-600italic.svg#Archivo")
            format("svg"); /* Legacy iOS */
}
/* archivo-700 - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 700;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
    src: local("Archivo Bold"), local("Archivo-Bold"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700.svg#Archivo") format("svg"); /* Legacy iOS */
}
/* archivo-700italic - latin-ext_latin */
@font-face {
    font-family: "Archivo";
    font-style: italic;
    font-weight: 700;
    src: url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
    src: local("Archivo Bold Italic"), local("Archivo-BoldItalic"),
        url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo/archivo-v7-latin-ext_latin-700italic.svg#Archivo")
            format("svg"); /* Legacy iOS */
}

/* chivo-300 - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: normal;
    font-weight: 300;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
    src: local("Chivo Light"), local("Chivo-Light"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-300italic - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: italic;
    font-weight: 300;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300italic.eot"); /* IE9 Compat Modes */
    src: local("Chivo Light Italic"), local("Chivo-LightItalic"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-300italic.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-regular - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: normal;
    font-weight: 400;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Chivo Regular"), local("Chivo-Regular"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-regular.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-italic - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: italic;
    font-weight: 400;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
    src: local("Chivo Italic"), local("Chivo-Italic"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-italic.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-700 - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: normal;
    font-weight: 700;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
    src: local("Chivo Bold"), local("Chivo-Bold"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-700italic - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: italic;
    font-weight: 700;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
    src: local("Chivo Bold Italic"), local("Chivo-BoldItalic"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-700italic.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-900 - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: normal;
    font-weight: 900;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900.eot"); /* IE9 Compat Modes */
    src: local("Chivo Black"), local("Chivo-Black"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900.svg#Chivo") format("svg"); /* Legacy iOS */
}
/* chivo-900italic - latin-ext_latin */
@font-face {
    font-family: "Chivo";
    font-style: italic;
    font-weight: 900;
    src: url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900italic.eot"); /* IE9 Compat Modes */
    src: local("Chivo Black Italic"), local("Chivo-BlackItalic"),
        url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900italic.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/chivo/chivo-v12-latin-ext_latin-900italic.svg#Chivo") format("svg"); /* Legacy iOS */
}

/* oswald-200 - latin-ext_latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 200;
    src: url("../media/fonts/oswald/oswald-v35-latin-ext_latin-200.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../media/fonts/oswald/oswald-v35-latin-ext_latin-200.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-200.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-200.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-200.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-200.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-300 - latin-ext_latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 300;
    src: url("../media/fonts/oswald/oswald-v35-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../media/fonts/oswald/oswald-v35-latin-ext_latin-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-300.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-300.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-300.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-regular - latin-ext_latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 400;
    src: url("../media/fonts/oswald/oswald-v35-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../media/fonts/oswald/oswald-v35-latin-ext_latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-regular.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-500 - latin-ext_latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 500;
    src: url("../media/fonts/oswald/oswald-v35-latin-ext_latin-500.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../media/fonts/oswald/oswald-v35-latin-ext_latin-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-500.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-500.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-600 - latin-ext_latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 600;
    src: url("../media/fonts/oswald/oswald-v35-latin-ext_latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../media/fonts/oswald/oswald-v35-latin-ext_latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-600.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-600.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-700 - latin-ext_latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 700;
    src: url("../media/fonts/oswald/oswald-v35-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../media/fonts/oswald/oswald-v35-latin-ext_latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-700.woff")
            format("woff"),
        /* Modern Browsers */ url("../media/fonts/oswald/oswald-v35-latin-ext_latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/oswald/oswald-v35-latin-ext_latin-700.svg#Oswald") format("svg"); /* Legacy iOS */
}

/* archivo-black-regular - latin-ext_latin */
@font-face {
    font-family: "Archivo Black";
    font-style: normal;
    font-weight: 400;
    src: url("../media/fonts/archivo_black/archivo-black-v10-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Archivo Black Regular"), local("ArchivoBlack-Regular"),
        url("../media/fonts/archivo_black/archivo-black-v10-latin-ext_latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../media/fonts/archivo_black/archivo-black-v10-latin-ext_latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../media/fonts/archivo_black/archivo-black-v10-latin-ext_latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../media/fonts/archivo_black/archivo-black-v10-latin-ext_latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../media/fonts/archivo_black/archivo-black-v10-latin-ext_latin-regular.svg#ArchivoBlack")
            format("svg"); /* Legacy iOS */
}
